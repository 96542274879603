<template>
  <van-nav-bar
    class="gx-van-nav-bar"
    :class="props.style ? `gx-van-nav-bar--${props.style}` : ''"
    :fixed="props.fixed || true"
    :left-arrow="props.leftArrow || true"
    :title="props.title || route?.meta?.title"
    :border="props.border || false"
    :safe-area-inset-top="true"
    :style="{
      // 空出app顶部状态栏的高度
      paddingTop: `${px2rem(sharedStore?.C5ToolBarHeight || 0)}rem`
    }"
    @click-left="clickLeft"
  >
    <!-- 将所有slot传递给vant -->
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </van-nav-bar>
</template>

<script setup lang="ts">
import { useSharedStore } from '@/stores/shared'

interface IProps {
  fixed?: boolean
  leftArrow?: boolean
  title?: string
  border?: boolean
  style?: object
  customGoBack?: boolean
}
const props = defineProps<IProps>()
const emit = defineEmits(['goBack'])

const route = useRoute()
const router = useRouter()
const sharedStore = useSharedStore()

const clickLeft = () => {
  if (props.customGoBack) {
    emit('goBack')
    return
  }
  if (isInApp) {
    const initialRoute = window.sessionStorage.getItem('initialRoute')
    if (initialRoute && route.fullPath === initialRoute) {
      native?.back()
    } else {
      router.back()
    }
  } else {
    router.back()
  }
}
</script>

<style lang="scss">
.gx-van-nav-bar {
  line-height: 22px;
  z-index: 1000;

  .van-nav-bar {
    background: $color-white;

    &__content {
      height: 46px;
    }

    &__left {
      padding: 0 15px;
    }

    &__arrow {
      font-size: 20px;
      color: $color-black-153;

      &::before {
        content: '\e64d';
        font-family: 'icon';
      }
    }

    &__title {
      font-size: 17px;
      color: $color-black-153;
    }
  }

  &--dark {
    background: $color-black-0;

    .van-nav-bar__arrow {
      color: $color-white;
    }

    .van-nav-bar__title {
      color: $color-white;
    }
  }
}
</style>
